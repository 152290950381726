'use client';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatMsg from "../../../util/formatMsg";
import { RootState } from 'sharedresources/src/redux/reducers';
import SpinWrapper from "../../../components/SpinWrapper/page";
import { loginActions, PrefixIconButton, Helper } from 'sharedresources';  // Imported from sharedresources
import authHelper from "../../../../../sharedresources/src/okta/page"
import './style.css'

const emailLogin: React.FC<any> = () => {
  const { isLoading, authSuccess, }: any = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const [email, setEmail] = useState<any>();
  const [password, setPassword] = useState<any>();
  const [hideButtons, setHideButtons] = useState(true)

  const router = useRouter();
  function submit(loginType) {
    try {
      dispatch(loginActions.setLoading(null))
      let auth0 = authHelper.getauth0Instance(loginType)
      auth0.loginWithRedirect()
    } catch (err) {
    }
  }

  useEffect(() => {
    if (Helper.getUrlParam("code")) {
      setTimeout(() => {
        setHideButtons(false)
      }, 1000);
    } else setHideButtons(false)
    const handleLoad = async () => {
      let auth0 = authHelper.getauth0Instance(null)
      const redirectResult = await auth0.handleRedirectCallback();

      // logged in. you can get the user profile like this:
      const user = await auth0.getUser();
      // if (!user || !user.email_verified) {
      //   notification("error", "User doesn't exist")
      //   return;
      // }
      dispatch(loginActions.signIn({ email, password, noChannel: true, user }))
    };
    if (getUrlParam("code") && getUrlParam("state")) {
      handleLoad()
    }
  }, []);
  function getUrlParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  useEffect(() => {
    if (authSuccess) {
      router.push('/login/userSheet');
    }
  }, [authSuccess])

  return (
    <SpinWrapper spinning={isLoading} style={{ height: "60%", maxHeight: "none" }}>
      {hideButtons || isLoading ? <></> : <div className="login-container">
        <div className="login-left-panel">
          <div className="logo-container">
            <img src="/images/logo.png" alt="logo" className='login-logo' />
            {/* <CustomLogo hideBackButton={true} /> */}
          </div>

          <div className="login-options">

            <PrefixIconButton
              title={formatMsg("signinWithEmail")}
              backgroundColor={"#7413BD"}
              fontColor={"#FFFFFF"}
              paddingLeft="30px"
              paddingRight="30px"
              marginTop="8px"
              height={"48px"}
              width={"100%"}
              onClick={() => submit(null)}
              borderRadius={"8px"}
              fontWeight={"800"}
              fontSize={"var(--font-size-18)"}
            />
            <div className="divider">
              <span>OR</span>
            </div>
            <PrefixIconButton
              title={formatMsg("signinWithPhone")}
              backgroundColor={"#FFFFFF"}
              fontColor={"#333333"}
              paddingLeft="30px"
              paddingRight="30px"
              marginTop="8px"
              height={"48px"}
              width={"100%"}
              onClick={() => submit("sms")}
              border={true}
              borderColor="var(--Mid-Purple, #7413BD)"
              borderRadius={"8px"}
              imgSrc={"/images/dial.png"}
              fontWeight={"700"}
              fontSize={"14px"}
            />
          </div>
        </div>
        <div className='login-promo-section'>
          <img src="/images/login.png" className="login-image" alt="calender" />
        </div>
      </div>}
      {/* <SignInStyleWrapper className="isoSignInPage">
        {hideButtons || isLoading ? <></> :
          <div className="isoLoginContentWrapper" >
            <div className="isoLoginContent" >
              <CustomLogo hideBackButton={true} />
              <div className="isoSignInForm" >

                <Form>
                 

                  < div id="recaptcha-container" />
                  <div className="isoCenterComponent isoHelperWrapper" >


                    <CustomButtonFilled
                      onClick={() => submit(null)}
                    >
                      <div className="button-text" >
                        {formatMsg("signinWithEmail")}
                      </div>
                    </CustomButtonFilled>
                    <CustomButtonFilled
                      onClick={() => submit("sms")}
                      style={{ marginTop: 20 }}
                    >
                      <div className="button-text" >
                        {formatMsg("signinWithPhone")}

                      </div>
                    </CustomButtonFilled>
                    < br />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        }
      </SignInStyleWrapper> */}
    </SpinWrapper >
  )
};
export default emailLogin;